import { render, staticRenderFns } from "./userTemplatesTableInEntityProfiles.vue?vue&type=template&id=ff231698&scoped=true"
import script from "./userTemplatesTableInEntityProfiles.vue?vue&type=script&lang=js"
export * from "./userTemplatesTableInEntityProfiles.vue?vue&type=script&lang=js"
import style0 from "./userTemplatesTableInEntityProfiles.vue?vue&type=style&index=0&id=ff231698&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ff231698",
  null
  
)

export default component.exports